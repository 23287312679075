export function api() {
    let baseApi = window.location.host === 'calc.chery.ru' || window.location.host === 'service-calculator.prod.logicstars.ru' ?
        'https://pro.api.prod.logicstars.ru/api/ServiceMaintenanceCalculatorNew/' :
        'https://pro.api.dev.logicstars.ru/api/ServiceMaintenanceCalculatorNew/';
    let authUrl = window.location.host === 'calc.chery.ru' ?
        'https://auth.logicstars.ru/api/guesttoken/chery' :
        'https://auth.logicstars.ru/api/guesttoken/chery_test';
    let getToken = () => {return localStorage.getItem( window.location.host === 'calc.chery.ru' ? 'productToken' : 'token');};
    let setToken = (token) => {localStorage.setItem(window.location.host === 'calc.chery.ru' ? 'productToken' : 'token', token);};

    let get = (url) => {
        return innerCall('GET', url);
    };

    let post = (url, body) => {
        return innerCall('POST', url, body);
    };

    let innerCall = (method, url, body, tokenName = (window.location.host === 'calc.chery.ru' ? 'productToken' : 'token'), isFile = false) => {
        let call = (url, token) => {
            let initForFetch = {
                headers: {
                    'authorization': 'Bearer ' + token
                },
                method: method,
            };
            if(method === 'POST' && body !== undefined && body !== null && (typeof body !== 'string')){
                const formData  = new FormData();
                for(const name in body){
                    if(typeof body[name])
                        formData.append(name, body[name]);
                    else
                        formData.append(name, body[name], body[name].name);
                }

                initForFetch.body = formData;
            }
            if(method === 'POST' && body !== undefined && body !== null && (typeof body === 'string')){
                initForFetch.body = JSON.stringify(encodeURI(body));
                initForFetch.headers = {
                    'authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                };
            }
            if(isFile)
                return  fetch(url, initForFetch);

            return  fetch(url, initForFetch)
            .then((response) => {
                return response.json();
            });
        };

        if(getToken() === null) {
            return fetch(authUrl)
                .then((response) => {
                    return response.text();
                })
                .then(token => {
                    setToken(token);
                    return call(url, localStorage.getItem(tokenName));
                });
        }
        return call(url, localStorage.getItem(tokenName));
    };
    return {
        getDealers: () => {return get(baseApi.replace('ServiceMaintenanceCalculatorNew/', '') + 'Dealer/GetList?mode=2');},
        getCarInfo : (vin) => {return get(baseApi + 'GetCarInfo?vin=' + vin);},
        getCarFamilyAndVersionList : () => {return get(baseApi + 'GetCarFamilyAndVersionList');},
        getMaintenanceInfo : (familyId, versionId, carId) => {return get(baseApi + 'GetMaintenanceInfo?familyId=' + familyId + '&versionId=' + versionId + '&carId=' + carId);},
        getMaintenancePrice : (familyId, versionId, serviceBookId, serviceBookColumnId, cityDescr) => {return get(baseApi + 'GetMaintenancePrice?familyId=' + familyId + '&versionId=' + versionId + '&serviceBookId=' + serviceBookId + '&serviceBookColumnId=' + serviceBookColumnId + (cityDescr !== undefined ? '&cityDescr=' + cityDescr : ''));},
        getAdditionalWorks : (familyId, versionId, dealerPointId, serviceBookId, serviceBookColumnId) => {return get(baseApi + 'GetAdditionalWorks?familyId=' + familyId + '&versionId=' + versionId + '&dealerPointId=' + dealerPointId+ '&serviceBookId=' + serviceBookId + '&serviceBookColumnId=' + serviceBookColumnId);},
        getCalculation : (familyId, versionId, dealerPointId, serviceBookId, serviceBookColumnId, additionalWorks) => {return get(baseApi + 'GetCalculation?familyId=' + familyId + '&versionId=' + versionId + '&dealerPointId=' + dealerPointId+ '&serviceBookId=' + serviceBookId + '&serviceBookColumnId=' + serviceBookColumnId + (additionalWorks !== undefined ? '&additionalWorks=' + additionalWorks : ''));},
        createMaintenanceOrder : (props) => {
            return post(baseApi + 'CreateMaintenanceOrder?' +
                'dealerPointId=' + props.dealerPointId +
                '&clientDescr=' + props.clientDescr +
                '&phone=' + props.phone +
                '&email=' + props.email +
                (props.clientId !== undefined ? '&clientId=' + props.clientId : '') +
                (props.vin !== undefined ? '&vin=' + props.vin : '') +
                '&licensePlate=' + props.licensePlate +
                (props.carId !== undefined ? '&carId=' + props.carId : '') +
                '&carTypeId=' + props.carTypeId +
                '&familyId=' + props.familyId +
                '&versionId=' + props.versionId +
                '&orderDatetime=' + props.orderDatetime +
                (props.notes !== undefined ? '&notes=' + props.notes : '') +
                '&serviceBookId=' + props.serviceBookId +
                '&serviceBookColumnId=' + props.serviceBookColumnId +
                (props.additionalWorks !== undefined ? '&additionalWorks=' + props.additionalWorks : '') +
                '&price=' + props.price
            );
        },
    };
}
